<template>
     <div id="valdifassa">
        <v-container fluid >
         <v-container class="mt-5 pt-5">
           <breadcrumbs></breadcrumbs>
         </v-container>
         <v-container>
          <v-row class="align-content-center align-center">
            <v-col cols="8" md="4" class="text-center mx-auto my-5">
             <h1>{{$t('router.404.title')}}</h1>
             <p>{{$t('router.404.description')}}</p>
             <v-btn color="primary" x-large  :to="{name:'home'}">
              Ciasa Nü
              <v-avatar class="mx-5 my-5" tile right dark ><v-img contain :src="require('@/assets/icon-white.svg')"></v-img></v-avatar>
             </v-btn>
            </v-col>
          </v-row>
         </v-container>
        </v-container>
     </div>
   </template>
   
   <script>
   

     export default {
       name: '404',
       data: () => ({
        language : null,
       }),
       created() {
       
       }
     }
   </script>
   <style scoped>
   #home{
     min-height: 100%;
   }
   </style>